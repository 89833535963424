AFRAME.registerComponent("chair-options", {
    schema: {},

    init: function () {
        this.bindMethods();
        this.chairEl = document.getElementById("chair");

        this.optionMapPaths = {
            1: "#chair-color-1",
            2: "#chair-color-3",
            3: "#chair-color-2",
        };

        this.selectedOption = 1;

        this.el.addEventListener("changeOption", this.changeOption);
    },

    changeOption: function (evt) {
        console.log(`chair newOption: ${this.optionMapPaths[evt.detail.newOption]}`);

        this.selectedOption = evt.detail.newOption;

        this.chairEl.setAttribute(
            "light-map-geometry",
            "lightMap",
            this.optionMapPaths[evt.detail.newOption]
        );
    },

    /**
     * bind methods used in callbacks
     */
    bindMethods: function () {
        this.changeOption = this.changeOption.bind(this);
    },
});
